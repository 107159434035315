import React, { useEffect, useState } from 'react'

import { Button, Grid, TextField, useTheme, useMediaQuery, Typography, Box } from '@mui/material'

import Env from '../../shared/config/envConfig'
import CheckboxComponent from '../../shared/components/FormComponents/CheckboxComponent'
import DropDownComponent, { EnumElement } from '../../shared/components/FormComponents/DropDownComponent'
import LabelWithTooltipComponent from '../../shared/components/FormComponents/LabelWithTooltipComponent'
import { useHistory } from 'react-router-dom'
import { setMessage } from '../../store/settings/settings.actions'
import { Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'

const Registration = () => {
  const theme = useTheme()
  const history = useHistory()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  // required fields
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [telephoneNumber, setTelephoneNumber] = useState<string>('')
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [gender, setGender] = useState<EnumElement |undefined>()
  const [shirtSize, setShirtSize] = useState<EnumElement | undefined>()
  const [birthYear, setBirthYear] = useState<string>('')

  // at least one
  const [availableOnFriday, setAvailableOnFriday] = useState<boolean>(false)
  const [availableOnSaturday, setAvailableOnSaturday] = useState<boolean>(false)
  const [availableOnSunday, setAvailableOnSunday] = useState<boolean>(false)

  // other fields
  const [friend, setFriend] = useState<string>('')
  const [notes, setNotes] = useState<string>('')
  const [helpedBefore, setHelpedBefore] = useState<boolean>(false)
  const [isClubMember, setIsClubMember] = useState<boolean>(false)

  // helper
  const [disabled, setDisabled] = useState<boolean>(true)
  const [showHint, setShowHint] = useState<boolean>(false)
  const [showRequiredHints, setShowRequiredHints] = useState<boolean>(false)
  const [showYearHint, setShowYearHint] = useState<boolean>(false)
  const [showEmailHint, setShowEmailHint] = useState<boolean>(false)
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }

  const checkValidity = () => {
    let temp = true
    if (availableOnFriday || availableOnSaturday || availableOnSunday) {
      temp = false
    }
    if (!firstName || !lastName || !telephoneNumber || !emailAddress || !address || !gender || !shirtSize || !birthYear) {
      temp = true
    }
    if (parseInt(birthYear, 10) < 1900 || parseInt(birthYear, 10) > new Date().getFullYear()) {
      temp = true
    }
    if (emailAddress && !validateEmail(emailAddress)) {
      temp = true
    }
    setDisabled(temp)
  }

  useEffect(() => {
    checkValidity()
  }, [firstName, lastName, telephoneNumber, emailAddress, address, gender, shirtSize, birthYear])

  useEffect(() => {
    checkValidity()
    if (availableOnFriday || availableOnSaturday || availableOnSunday) {
      setShowHint(false)
    }
  }, [availableOnFriday, availableOnSaturday, availableOnSunday])

  useEffect(() => {
    checkValidity()
    if (parseInt(birthYear, 10) < 1900 || parseInt(birthYear, 10) > new Date().getFullYear()) {
      setShowYearHint(true)
    } else {
      setShowYearHint(false)
    }
  }, [birthYear])

  useEffect(() => {
    checkValidity()
    if (emailAddress && !validateEmail(emailAddress)) {
      setShowEmailHint(true)
    } else {
      setShowEmailHint(false)
    }
  }, [emailAddress])

  const registerHelper = () => {
    const basePath = Env.basePath
    const restUrl = `${basePath}/api/import/helper`
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Accept', 'application/json')
    requestHeaders.set('Content-Type', 'application/json')
    const newHelper = {
      helper: {
        firstName,
        lastName,
        birthYear,
        emailAddress,
        telephoneNumber,
        gender: gender?.key,
        address,
        availableOnFriday,
        availableOnSaturday,
        availableOnSunday,
        shirtSize: shirtSize?.key,
        friend,
        helpedBefore,
        isClubMember,
        notes,
      },
    }
    fetch(restUrl,
      {
        method: 'POST',
        credentials: 'include',
        headers: requestHeaders,
        body: JSON.stringify(newHelper),
      })
      .then(res => res.json())
      .then(json => {
        history.push('/thankyou')
      })
      .catch(() => {
        setMessage({
          severity: Severity.error,
          message: 'Etwas ist schief gegangen! Versuche es zu einem späteren Zeitpunkt erneut.',
        })
      })
  }

  return (
    <Box sx={{ margin: '50px' }}>
      <Typography sx={{ marginTop: '20px' }} variant='body1'>Mit * markierte Pflichtfelder sind im Anmeldeformular
        auszufüllen
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}><Typography sx={{ marginTop: '20px' }} variant='h5'>Allgemeine Informationen</Typography>
        </Grid>
        <Grid item xs={downMd ? 12 : 6}>
          <TextField
            margin='normal'
            required
            error={showRequiredHints && !firstName}
            helperText={showRequiredHints && !firstName ? 'Vorname ist ein Pflichfeld' : ''}
            fullWidth
            id='firstname'
            label='Vorname'
            name='firstname'
            value={firstName}
            onChange={(event) => setFirstName(event.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={downMd ? 12 : 6}>
          <TextField
            margin='normal'
            required
            fullWidth
            error={showRequiredHints && !lastName}
            helperText={showRequiredHints && !lastName ? 'Nachname ist ein Pflichfeld' : ''}
            id='laststname'
            label='Nachname'
            name='laststname'
            value={lastName}
            onChange={(event) => setLastName(event.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={downMd ? 12 : 6}>
          <TextField
            margin='normal'
            required
            fullWidth
            error={(showRequiredHints && !emailAddress) || showEmailHint}
            helperText={showRequiredHints && !emailAddress ? 'E-Mail ist ein Pflichfeld' : (showEmailHint ? 'Die eingegebene E-Mail-Adresse ist nicht gültig' : '')}
            id='email'
            label='E-Mail'
            name='email'
            value={emailAddress}
            onChange={(event) => setEmailAddress(event.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={downMd ? 12 : 6}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='phone'
            error={showRequiredHints && !telephoneNumber}
            helperText={showRequiredHints && !telephoneNumber ? 'Telefon ist ein Pflichfeld' : ''}
            label='Telefon'
            name='phone'
            value={telephoneNumber}
            onChange={(event) => setTelephoneNumber(event.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin='normal'
            required
            fullWidth
            error={showRequiredHints && !address}
            helperText={showRequiredHints && !address ? 'Wohnort ist ein Pflichfeld' : ''}
            id='city'
            label='Wohnort'
            name='city'
            value={address}
            onChange={(event) => setAddress(event.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={downMd ? 12 : 4}>
          <TextField
            margin='normal'
            required
            fullWidth
            error={(showRequiredHints && !birthYear) || showYearHint}
            helperText={showRequiredHints && !birthYear ? 'Geburtsjahr ist ein Pflichfeld' : (showYearHint ? 'Bitte nur das Geburtsjahr als vierstellige Zahl eintragen' : '')}
            id='birthYear'
            label='Geburtsjahr'
            name='birthYear'
            value={birthYear}
            onChange={(event) => setBirthYear(event.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={downMd ? 12 : 4}>
          <DropDownComponent
            fullWidth
            error={showRequiredHints && !gender}
            helperText={showRequiredHints && !gender ? 'Geschlecht ist ein Pflichfeld' : ''}
            options={[
              {
                key: 'Male',
                value: 'Mann',
              },
              {
                key: 'Female',
                value: 'Frau',
              },
              {
                key: 'Diverse',
                value: 'Divers',
              },
            ]}
            disableClearable
            value={gender}
            label='Geschlecht'
            name='gender'
            onChange={(value) => setGender(value)}
          />
        </Grid>
        <Grid item xs={downMd ? 12 : 4}>
          <DropDownComponent
            fullWidth
            error={showRequiredHints && !shirtSize}
            helperText={showRequiredHints && !shirtSize ? 'T-Shirt Größe ist ein Pflichfeld' : ''}
            options={[
              {
                key: 'Small',
                value: 'S',
              },
              {
                key: 'Medium',
                value: 'M',
              },
              {
                key: 'Large',
                value: 'L',
              },
              {
                key: 'xLarge',
                value: 'XL',
              },
              {
                key: 'xxLarge',
                value: 'XXL',
              },
            ]}
            disableClearable
            value={shirtSize}
            label='T-Shirt Größe'
            name='shirtSize'
            onChange={(value) => setShirtSize(value)}
          />
        </Grid>
        <Grid item xs={12}><Typography sx={{ marginTop: '20px' }} variant='h5'>Einsatzzeiten</Typography> </Grid>
        {showHint &&
          <Typography sx={{ color: theme.palette.primary.main, marginTop: '10px', marginLeft: '16px' }} variant='body1'>Mindestens ein Tag muss ausgewählt sein!</Typography>}
        <Grid item xs={12}>
          <CheckboxComponent
            checked={availableOnFriday}
            id='availableOnFriday'
            name='availableOnFriday'
            label='Einsatz am Freitag, den 14.06.2024'
            onChange={(value) => setAvailableOnFriday(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxComponent
            checked={availableOnSaturday}
            id='availableOnSaturday'
            name='availableOnSaturday'
            label='Einsatz am Samstag, den 15.06.2024'
            onChange={(value) => setAvailableOnSaturday(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxComponent
            checked={availableOnSunday}
            id='availableOnSunday'
            name='availableOnSunday'
            label='Einsatz am Sonntag, den 16.06.2024'
            onChange={(value) => setAvailableOnSunday(value)}
          />
        </Grid>
        <Grid item xs={12}><Typography sx={{ marginTop: '20px' }} variant='h5'>Weitere Informationen</Typography>
        </Grid>
        <Grid item xs={12}>
          <CheckboxComponent
            checked={helpedBefore}
            id='helpedBefore'
            name='availableOnSunday'
            label='Ich habe schon mal beim Schloss-Triathlon Moritzburg geholfen'
            onChange={(value) => setHelpedBefore(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxComponent
            checked={isClubMember}
            id='isClubMember'
            name='isClubMember'
            label='Ich bin ein offizielles Vereinsmitglied des Moritzburger Triathlonverein e.V.'
            onChange={(value) => setIsClubMember(value)}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '20px' }}>
          <LabelWithTooltipComponent
            label='Eine/Ein Freundin/Freund wird auch mithelfen. Wir möchten gern gemeinsam eingeteilt werden.'
          />
        </Grid>
        <Grid item xs={downMd ? 12 : 6}>
          <TextField
            margin='normal'
            fullWidth
            id='friend'
            label='Name des Freundes'
            name='friend'
            value={friend}
            onChange={(event) => setFriend(event.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin='normal'
            fullWidth
            multiline
            minRows={3}
            id='notes'
            label='Besondere Fähigkeiten und Wünsche'
            name='notes'
            value={notes}
            onChange={(event) => setNotes(event.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={() => {
                if (disabled) {
                  if (availableOnFriday || availableOnSaturday || availableOnSunday) {
                    setShowHint(false)
                  } else {
                    setShowHint(true)
                  }
                  if (parseInt(birthYear, 10) < 1900 || parseInt(birthYear, 10) > new Date().getFullYear()) {
                    setShowYearHint(true)
                  } else {
                    setShowYearHint(false)
                  }
                  if (emailAddress && !validateEmail(emailAddress)) {
                    setShowYearHint(true)
                  } else {
                    setShowYearHint(false)
                  }
                  if (!firstName || !lastName || !telephoneNumber || !emailAddress || !address || !gender || !shirtSize || !birthYear) {
                    setShowRequiredHints(true)
                  } else {
                    setShowRequiredHints(false)
                  }
                } else {
                  registerHelper()
                }
              }}
            >
              Anmelden
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
export default Registration
