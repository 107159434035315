import React from 'react'

import { createStyles, makeStyles } from '@mui/styles'
import { Paper, Theme, Typography } from '@mui/material'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100% - 32)',
      padding: theme.spacing(2),
      margin: theme.spacing(2),
    },
  }),
)
const ThankYou: React.FC = () => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Typography variant='h5'>Willkommen beim Schlosstriathlon!<br /><br />
      </Typography>
      <Typography variant='body1'>
        <b>Liebe/r Helfer/in,</b><br /><br />

        herzlichen Dank für deine Registrierung als Helfer beim <b>22. Schlosstriathlon 2024</b>! Deine Bereitschaft,
        Teil unseres Teams zu werden, ist für den reibungslosen Ablauf dieser Veranstaltung von unschätzbarem Wert.<br />
        <br />

        Mit deinem Engagement trägst du nicht nur dazu bei, dass der Schlosstriathlon zu einem unvergesslichen Ereignis
        wird, sondern du unterstützt auch die sportlichen Träume und Ziele der Athletinnen und Athleten. Dein Einsatz
        ist ein wichtiger Baustein für den Erfolg dieser Veranstaltung, und wir schätzen deine Unterstützung sehr.<br />
        <br />

        Wir sind begeistert, dich als Teil unserer Helferfamilie zu begrüßen und freuen uns darauf, gemeinsam mit dir
        eine unvergessliche und erfolgreiche Veranstaltung zu gestalten. Deine Zeit, Energie und Hingabe machen den
        Schlosstriathlon erst zu dem besonderen Erlebnis, das er ist.<br /> <br />

        <b>Das Team des Schlosstriathlons</b>
      </Typography>
    </Paper>
  )
}
export default ThankYou
