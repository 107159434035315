import React, { useState, useEffect } from 'react'
import { FormControlLabel, Checkbox, Typography } from '@mui/material'
import LabelWithTooltipComponent from './LabelWithTooltipComponent'

interface ISwitchComponentProps {
  checked: boolean
  name?: string
  id?: string
  disabled?: boolean
  color?: 'primary' | 'secondary'
  onChange?: (checked: boolean) => void
  label?: string
  description?: string
}

const CheckboxComponent: React.FC<ISwitchComponentProps> = (props) => {
  const color: 'primary' | 'secondary' = props.color || 'primary'
  const [checked, setChecked] = useState(props.checked)

  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked])

  const handleChange = (checked: boolean) => {
    setChecked(checked)
    props.onChange && props.onChange(checked)
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          disabled={props.disabled}
          onChange={((event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.checked))}
          name={props.name}
          color={color}
        />
      }
      label={
        <>
          {props.label &&
            <Typography variant='body2' style={{ display: 'flex' }}>
              <LabelWithTooltipComponent
                label={props.label}
                description={props.description}
              />
            </Typography>}
        </>
      }
    />
  )
}

export default CheckboxComponent
