import React from 'react'

import { createStyles, makeStyles } from '@mui/styles'
import { Box, Button, Paper, Theme, Typography } from '@mui/material'
import Env from '../../shared/config/envConfig'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100% - 32)',
      padding: theme.spacing(2),
      margin: theme.spacing(2),
    },
  }),
)

interface Props {
  token: string | null
}

const Confirmation: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  const requestHeaders: HeadersInit = new Headers()
  requestHeaders.set('Accept', 'application/json')
  requestHeaders.set('Content-Type', 'application/json')

  const sendConfirmation = () => {
    const helperEmailRestUrl = `${Env.basePath}/api/confirmation/${props.token}`
    fetch(helperEmailRestUrl,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => {
        console.log(res)
        window.location.href = 'https://schlosstriathlon.de/'
      })
      .catch(err => console.log(err))
  }

  return (
    <Paper className={classes.root}>
      <Typography variant='h5'>Willkommen beim Schlosstriathlon! Bestätige deine Teilnahme als
        Helfer!<br /><br />
      </Typography>
      <Typography variant='body1'>
        Herzlich willkommen beim <b>22. Schlosstriathlon 2024</b> – einem aufregenden Sportereignis, das durch
        die Unterstützung unserer engagierten Helfer erst möglich wird! Wir freuen uns, dass du dich entschieden hast,
        ein wichtiger Teil dieses
        spektakulären Events zu sein. <br /> <br />

        Um deine Teilnahme als Helfer zu bestätigen, bitten wir dich, auf den nachfolgenden Button zu klicken.
        Dieser Schritt ist entscheidend, damit wir wissen, dass wir mit dir rechnen können.
      </Typography>
      <Box sx={{ width: '100%', textAlign: 'center', margin: '20px 0' }}>
        <Button
          variant='contained'
          onClick={() => {
            sendConfirmation()
          }}
        >
          Hiermit bestätige ich meine Teilnahme als Helfer
        </Button>
      </Box>
      <Typography variant='body1'>
        Wir schätzen dein Engagement und freuen uns darauf, gemeinsam mit dir einen unvergesslichen 22. Schlosstriathlon
        2024 zu
        gestalten. Vielen Dank für deine Unterstützung!<br /> <br />

        Bei Fragen oder Problemen stehen wir gerne zur Verfügung.<br /> <br />

        Vielen Dank und bis bald beim Schlosstriathlon!<br /> <br />

        Das Team des Schlosstriathlons
      </Typography>
    </Paper>
  )
}
export default Confirmation
