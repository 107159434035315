import React, {
  useState,
} from 'react'

import SettingsButton from './SettingsButton'
import SettingsMenu from './SettingsMenu'
import { useSelector } from 'react-redux'
import { getUser } from '../../../store/settings/settings.selectors'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'

interface Props {
  doLogout: () => void
}

const HeaderComponent: React.FC<Props> = (props: Props) => {
  const user = useSelector(getUser)
  const history = useHistory()

  const [showSettingsMenu, setShowSettingsMenu] = useState(false)

  return (
    <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
      <img style={{ cursor: 'pointer' }} src='/schlosstriathlonLogo.png' onClick={() => history.push('/')} />
      {user &&
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SettingsButton
            user={user}
            showSettingsMenu={showSettingsMenu}
            setShowSettingsMenu={setShowSettingsMenu}
          />
          <SettingsMenu
            user={user}
            showSettingsMenu={showSettingsMenu}
            setShowSettingsMenu={setShowSettingsMenu}
            doLogout={props.doLogout}
          />
        </Box>}
    </Box>
  )
}

export default HeaderComponent
