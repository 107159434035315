import React, { useEffect, useRef, useState } from 'react'
import Env from '../../shared/config/envConfig'
import {
  Grid,
  Typography,
  useTheme,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, Box, Button,
} from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import CardComponent from './CardComponent'
import CheckIcon from '@mui/icons-material/Check'
import EmailIcon from '@mui/icons-material/Email'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'

import CheckboxComponent from '@3m5/crude-frontend/dist/shared/components/FormComponents/CheckboxComponent'
import formatDate from '@3m5/crude-frontend/dist/shared/util/formatDate'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '40px',
  },
}))

const Email: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()

  const requestHeaders: HeadersInit = new Headers()
  requestHeaders.set('Accept', 'application/json')
  requestHeaders.set('Content-Type', 'application/json')

  const StyledTableRow = styled(TableRow)(() => ({
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    [theme.breakpoints.down('md')]: {
      '& td, th': {
        border: 0,
      },
      '&:first-of-type': {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))

  const [mobileLayout, setMobileLayout] = useState(false)

  const [helperEmailData, setHelperEmailData] = useState<any>()
  const [checkAll, setCheckAll] = useState<boolean>(false)
  const [sendEmailTo, setSendEmailTo] = useState<string[]>([])

  const loadHelperEmailList = () => {
    const helperEmailRestUrl = `${Env.basePath}/api/dashboard/helperEmail`
    fetch(helperEmailRestUrl,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        json.map((elem) => {
          elem.checked = emailDeliveryRequired(elem)
          return elem
        })
        setHelperEmailData(json)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    loadHelperEmailList()
  }, [],
  )

  useEffect(() => {
    const array: string[] = []
    helperEmailData && helperEmailData.map((elem) => {
      elem.checked && array.push(elem.id)
    })
    setSendEmailTo(array)
  }, [helperEmailData],
  )

  useEffect(() => {
    const tempData = helperEmailData?.map((elem) => {
      elem.checked = checkAll
      return elem
    })
    setHelperEmailData(tempData)
  }, [checkAll],
  )

  const rootRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (rootRef.current) {
      const element: HTMLElement = rootRef.current

      setMobileLayout(element.offsetWidth < 600)

      const observer = new ResizeObserver(() => {
        setMobileLayout(element.offsetWidth < 600)
      })
      observer.observe(element)

      return () => {
        observer.disconnect(element)
      }
    }
  }, [rootRef, setMobileLayout])

  const sendEmailToSingleHelper = (helperId: string) => {
    sendingEmails([helperId])
  }
  const sendEmailToSelectedHelper = () => {
    sendingEmails(sendEmailTo)
  }

  const sendingEmails = (sendTo: string[]) => {
    const basePath = Env.basePath
    const restUrl = `${basePath}/api/email/confirmation`

    const sendRequest = {
      helper: sendTo,
    }

    fetch(restUrl,
      {
        method: 'POST',
        body: JSON.stringify(sendRequest),
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        loadHelperEmailList()
      })
      .catch(err => console.log(err))
  }

  const emailDeliveryRequired = (helper: any) => {
    const lastEmail = helper.infoMailSentOn
    const lastPlanChange = helper.lastPlanChange
    let emailRequired = false
    if ((lastEmail && lastPlanChange && new Date(lastEmail) < new Date(lastPlanChange)) || (lastPlanChange && !lastEmail)) {
      emailRequired = true
    }
    return emailRequired
  }

  return (
    <Grid ref={rootRef} container className={classes.root}>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={12} container spacing={3} direction={mobileLayout ? 'column-reverse' : 'row'}>
          <Grid item xs={mobileLayout ? undefined : 12}>
            <CardComponent padding={mobileLayout ? 0 : undefined} height='unset'>
              <Stack spacing={1}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography sx={{ padding: mobileLayout ? 2 : 0, paddingBottom: 0 }} variant='h5'>E-Mail Benachrichtigung</Typography>
                  </Box>
                  <Box>
                    <Button onClick={() => sendEmailToSelectedHelper()}>{`${sendEmailTo.length} E-Mails versenden`}</Button>
                  </Box>
                </Box>
                <TableContainer>
                  <Table>
                    {!mobileLayout &&
                      <TableHead>
                        <StyledTableRow>
                          <TableCell sx={{ fontWeight: 600, textAlign: 'center' }}>
                            <CheckboxComponent checked={checkAll} onChange={(value) => setCheckAll(value)} />
                          </TableCell>
                          <TableCell sx={{ fontWeight: 600 }}><Typography>Name</Typography></TableCell>
                          <TableCell sx={{ fontWeight: 600, textAlign: 'center' }}><Typography>Vereinsmitglied</Typography></TableCell>
                          <TableCell sx={{ fontWeight: 600, textAlign: 'center' }}><Typography>Zuvor geholfen</Typography></TableCell>
                          <TableCell sx={{ fontWeight: 600 }}><Typography>Benachrichtigt am</Typography></TableCell>
                          <TableCell sx={{ fontWeight: 600, textAlign: 'center' }} />
                        </StyledTableRow>
                      </TableHead>}
                    <TableBody>
                      {helperEmailData && helperEmailData.length > 0
                        ? (
                          <>
                            {helperEmailData?.map((helper: any, index: number) => (
                              <StyledTableRow
                                key={index}
                              >
                                {mobileLayout ? (
                                  <TableCell>
                                    <Grid container spacing={1}>
                                      <Grid item xs={6}><Typography>Name</Typography></Grid>
                                      <Grid item xs={6}><Typography>{`${helper.firstName} ${helper.lastName}`}</Typography></Grid>
                                      <Grid item xs={6}><Typography>Vereinsmitglied</Typography></Grid>
                                      <Grid item xs={6}><Typography>{helper.isClubMember && <CheckIcon width='0.8em' />}</Typography></Grid>
                                      <Grid item xs={6}><Typography>Zuvor geholfen</Typography></Grid>
                                      <Grid item xs={6}><Typography>{helper.helpedBefore && <CheckIcon width='0.8em' />}</Typography></Grid>
                                      <Grid item xs={6}><Typography>Benachrichtigt am</Typography></Grid>
                                      <Grid item xs={6}><Typography>{formatDate(new Date(helper.infoMailSentOn).getTime() / 1000)}</Typography></Grid>
                                      <Grid item xs={6}><Typography>Erneut benachrichtigen</Typography></Grid>
                                      <Grid item xs={6}>
                                        <Typography>
                                          <EmailIcon sx={{ cursor: 'pointer' }} onClick={() => sendEmailToSingleHelper(helper.id)} />
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </TableCell>)
                                  : (
                                    <>
                                      <TableCell sx={{ textAlign: 'center' }}>
                                        <CheckboxComponent
                                          checked={helper.checked}
                                          onChange={(value) => {
                                            const tempData = [...helperEmailData]
                                            const index = tempData.findIndex(elem => elem.id === helper.id)
                                            tempData[index].checked = value
                                            setHelperEmailData(tempData)
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell sx={{ textAlign: 'left' }}>{`${helper.firstName} ${helper.lastName}`}</TableCell>
                                      <TableCell sx={{ textAlign: 'center' }}>{helper.isClubMember && <CheckIcon />}</TableCell>
                                      <TableCell sx={{ textAlign: 'center' }}>{helper.helpedBefore && <CheckIcon />}</TableCell>
                                      <TableCell sx={{ textAlign: 'left' }}>{formatDate(new Date(helper.infoMailSentOn).getTime() / 1000)}</TableCell>
                                      <TableCell sx={{ textAlign: 'center' }}>{emailDeliveryRequired(helper) && <ReportProblemIcon />}</TableCell>
                                    </>)}
                              </StyledTableRow>
                            ))}
                          </>
                        )
                        : (
                          <TableCell colSpan={4} sx={{ width: '100%', textAlign: 'center' }}>
                            <Typography variant='body1'>Keine Helfer verfügbar.</Typography>
                          </TableCell>)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </CardComponent>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Email
