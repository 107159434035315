import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  Card,
  Divider,
  List,
  ListItem,
  Theme,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import SettingsIcon from '@mui/icons-material/Settings'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import TableViewIcon from '@mui/icons-material/TableView'
import DashboardIcon from '@mui/icons-material/Dashboard'
import EmailIcon from '@mui/icons-material/Email'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

import { getCrudeAppName } from '../../../store/settings/settings.selectors'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      position: 'absolute',
      top: 30,
      bottom: 'auto',
      left: 'auto',
      backgroundColor: theme.palette.background.paper,
      minWidth: 170,
      zIndex: 1001,
    },
    button: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      fontSize: '14px!important',
      fontWeight: theme.typography.fontWeightRegular,
      padding: '7px',
      color: theme.palette.text.primary,
      borderRadius: '0px',
    },
  }),
)

export interface SettingsMenuProps<> {
  user: any
  showSettingsMenu: boolean
  setShowSettingsMenu: (show: boolean) => void
  doLogout: () => void
}

const SettingsMenu = (props: SettingsMenuProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const appName: string = useSelector(getCrudeAppName)

  const setShowSettingsMenu = props.setShowSettingsMenu
  const doLogout = props.doLogout

  const onSelectDashboard = useCallback(
    () => {
      setShowSettingsMenu(false)
      history.push('/')
    },
    [setShowSettingsMenu],
  )

  const onSelectCrude = useCallback(
    () => {
      setShowSettingsMenu(false)
      history.push(`/${appName}/Helper/`)
    },
    [setShowSettingsMenu],
  )

  const onSelectEmail = useCallback(
    () => {
      setShowSettingsMenu(false)
      history.push('/email')
    },
    [setShowSettingsMenu],
  )

  const onSelectDownload = useCallback(
    () => {
      setShowSettingsMenu(false)
      history.push('/download')
    },
    [setShowSettingsMenu],
  )

  const onSelectSettings = useCallback(
    () => {
      setShowSettingsMenu(false)
      history.push('/settings')
    },
    [setShowSettingsMenu],
  )

  const onLogout = useCallback(
    () => {
      setShowSettingsMenu(false)
      doLogout()
    },
    [setShowSettingsMenu, doLogout],
  )
  if (!props.showSettingsMenu) {
    return (<div />)
  }
  return (
    <>
      {
        !props.showSettingsMenu
          ? <div />
          : (
            <Card
              className={classes.dialog}
            >
              <List>
                <ListItem>
                  <IconButton className={classes.button} onClick={onSelectDashboard}>
                    <DashboardIcon style={{ marginRight: 10 }} />{t('common:dashboard')}
                  </IconButton>
                </ListItem>
                <ListItem>
                  <IconButton className={classes.button} onClick={onSelectCrude}>
                    <TableViewIcon style={{ marginRight: 10 }} />Verwaltung
                  </IconButton>
                </ListItem>
                <ListItem>
                  <IconButton className={classes.button} onClick={onSelectEmail}>
                    <EmailIcon style={{ marginRight: 10 }} />E-Mail
                  </IconButton>
                </ListItem>
                <ListItem>
                  <IconButton className={classes.button} onClick={onSelectDownload}>
                    <FileDownloadIcon style={{ marginRight: 10 }} />Downloads
                  </IconButton>
                </ListItem>
                <ListItem>
                  <IconButton className={classes.button} onClick={onSelectSettings}>
                    <SettingsIcon style={{ marginRight: 10 }} /> {t('common:settings')}
                  </IconButton>
                </ListItem>
                <li>
                  <Divider />
                </li>
                <ListItem>
                  <IconButton className={classes.button} onClick={onLogout}>
                    <PowerSettingsNewIcon style={{ marginRight: 10 }} /> {t('common:logout')}
                  </IconButton>
                </ListItem>
              </List>
            </Card>
          )
      }
    </>
  )
}

export default SettingsMenu
