import { createTheme, Theme, ThemeOptions } from '@mui/material'

export function getAppTheme (darkMode?: boolean): Theme {
  const lightTheme = {
    palette: {
      primary: {
        main: '#d90000',
      },
      secondary: {
        main: '#333333',
        dark: '#151515',
        light: '#9A9A9A',
      },
      background: {
        paper: '#ffffff',
        default: '#efefef',
      },
    },
    typography: {
      fontSize: 12,
    },
    overrides: {
      MuiAutocomplete: {
        option: {
          '&[data-focus="true"]': {
            color: '#d90000',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            border: '2px solid',
            borderColor: '#d90000',
          },
          '&$focused $notchedOutline': {
            borderColor: '#d90000',
            borderWidth: 1,
          },
        },
      },
      MuiTabs: {
        root: {
          backgroundColor: '#f5f5f5',
          borderTop: '2px solid',
          borderColor: '#ebe7e9',
        },
      },
      MuiTab: {
        root: {
          '&$selected': {
            backgroundColor: '#ffffff',
            borderTop: '3px solid',
            borderColor: '#d90000',
            color: '#d90000',
          },
        },
      },
      MuiButton: {
        root: {
          border: '1px solid #B72841',
          color: '#ffffff',
          backgroundColor: '#d90000',
          minWidth: '0',
          boxShadow: 'none',
        },
        containedSecondary: {
          backgroundColor: '#ffffff',
          border: '1px solid #d90000',
          color: '#FF3939',
          '&:hover': {
            backgroundColor: '#ffffff',
            border: '1px solid #d90000',
            color: '#800000',
          },
        },
        containedPrimary: {
          color: '#ffffff',
          backgroundColor: '#d90000',
          border: '1px solid #d90000',
          '&:hover': {
            backgroundColor: '#ffffff',
            color: '1px solid #d90000',
          },
        },
      },
      MuiPickersToolbarButton: {
        toolbarBtn: {
          border: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
            border: 'none',
          },
        },
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          '&:hover': {
            color: '#d90000',
          },
        },
      },
    },
  }

  const darkTheme: ThemeOptions = {
    typography: {
      fontSize: 12,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            border: '1px solid #FF3939',
            minWidth: '0',
            boxShadow: 'none',
          },
          text: {
            color: '#d90000',
          },
        },
      },
    },
    palette: {
      mode: 'dark',
      primary: {
        main: '#d90000',
        light: 'rgba(255, 255, 255, 0.5)',
        dark: '#121212',
      },
      grey: {
        A100: 'transparent',
        A200: 'rgba(255, 255, 255, 0.08)',
      },
      secondary: {
        main: '#d90000',
      },
      background: {
        default: '#383838',
        paper: '#414141',
      },
      text: {
        primary: 'rgba(255, 255, 255, 0.5)',
        secondary: 'rgba(255, 255, 255, 0.3)',
      },
    },
  }

  const theme: Theme = createTheme(darkMode ? darkTheme : lightTheme)

  return theme
}
